import React from "react"
import styled, { keyframes } from "styled-components"
import color from "../components/colors"

const AnimatedLogo = () => {
  let isDesktop = false
  if (typeof window !== `undefined`) {
    isDesktop = window.innerWidth > 980
  }

  return (
  <>
		<LogoAnimated width="500" height="500" viewBox="0 0 500 500">
			<LineBR id="line-br" d="M 290.36831,440.11194 440.87363,179.31739 c 10.6633,-18.58466 -2.742,-41.43466 -24.0687,-41.43466 H 290.36831" />
			<LineBR2 id="line-br" d="M 290.36831,440.11194 440.87363,179.31739 c 10.6633,-18.58466 -2.742,-41.43466 -24.0687,-41.43466 H 290.36831" />
			<LineBR3 id="line-br" d="M 290.36831,440.11194 440.87363,179.31739 c 10.6633,-18.58466 -2.742,-41.43466 -24.0687,-41.43466 H 290.36831" />
			<LineBL id="line-bl" d="M 209.63167,137.57804 H 83.19504 c -21.32666,0 -34.73199,23.15469 -24.06866,41.43468 l 150.50529,260.79455" />
			<LineTL id="line-tl" d="M 209.63167,59.88806 59.12638,320.68267 c -10.66333,18.58463 2.742,41.43463 24.06866,41.43463 h 126.43663" />
			<RectL id="rect-l" height="33.208656" width="33.208656" x="193.17964" y="231.41531" />
			<RectR height="33.208656" width="33.208656" x="273.61163" y="231.41531" />
			<LineTR id="line-tr" d="m 290.36831,362.1173 h 126.43663 c 21.3267,0 34.732,-23.15463 24.0687,-41.43463 L 290.36831,59.88806" />
		</LogoAnimated>
  </>
  )
}

export default AnimatedLogo

const LogoAnimated = styled.svg`
`
const rotateLine = keyframes`
  to {
    transform: rotateZ(180deg);
		opacity: 1;
  }
`
const rotateLineZ = keyframes`
  to {
    transform: rotateX(-180deg);
		opacity: 1;
  }
`
const rotateLineY = keyframes`
  to {
    transform: rotateY(-180deg);
		opacity: 1;
  }
`
const drawLine = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`
const LineDef = styled.path`
	fill: transparent;
	stroke-width: 30px;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
`
const LineBR = styled(LineDef)`
	stroke: ${color.primary};
	stroke-dasharray: 490;
	stroke-dashoffset: 490;
	transform-origin: center;
	animation-name: ${drawLine};
	animation-delay: 1s;
`
const LineBR2 = styled(LineDef)`
	opacity: 0;
	stroke: ${color.primaryLight};
	animation-name: ${rotateLineZ};
	animation-delay: 1.5s;
	transform-origin: center;
	transform: rotateX(0);
`
const LineBR3 = styled(LineDef)`
	opacity: 0;
	stroke: ${color.primaryLight};
	transform-origin: center;
	transform: rotateY(0);
	animation-name: ${rotateLineY};
	animation-delay: 2s;
`
const LineTR = styled(LineDef)`
	opacity: 0;
	stroke: ${color.primary};
	transform-origin: center;
	transform: rotateY(0);
	animation-delay: 2.2s;
	animation-name: ${rotateLineY};
`
const LineTL = styled(LineDef)`
	display: none;
	stroke: ${color.primary};
	opacity: 0;
	animation-name: ${rotateLine};
	animation-delay: 3s;
	transform-origin: center;
	transform: rotateZ(0);
`
const LineBL = styled(LineDef)`
	display: none;
	stroke: ${color.primaryLight};
	opacity: 0;
	animation-name: ${rotateLine};
	animation-delay: 3s;
	transform-origin: center;
	transform: rotateZ(-180deg);
`
const RectDef = styled.rect`
	fill: transparent;
`
const RectL = styled(RectDef)`
	fill: ${color.primaryLight};
`
const RectR = styled(RectDef)`
	fill: ${color.primary};
`
